import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import styled from 'styled-components';
import {device} from '../styles/Breakpoints'

import HeaderAndMenu from '../components/HeaderAndMenu';
import MyMarquee from '../components/Marquee'
import Cursor from '../components/Cursor';
import Footer from '../components/Footer';

import Masonry from 'react-masonry-css'
import BlockContent from '@sanity/block-content-to-react';
import { Helmet } from "react-helmet";

const GalleryPageStyles = styled.main`
    position: relative;
    .masonry-grid__column {
        padding-left: 72px; /* gutter size */
    }
    .masonry-grid {
      margin-left: -72px; /* gutter size offset */
    }
    .masonry-grid__column > div { 
      margin-bottom: 72px;
    }

    .gallery__title-bar {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      height: 170px;
      text-transform: uppercase;
      @media ${device.$small} {
        padding: 104px 0 36px;
        height: auto;
        text-align: center;
        align-items: center;
      }
      .back-button {
        margin-bottom: 12px;
        font-weight: normal;
        font-size: 10px;
        display: flex;
        align-items: baseline;
        &:hover {
          font-weight: bold;
        }
        @media ${device.$small} {
          font-size: 14px;
        }
        .left-arrow {
          height: 8px;
          width: 8px;
          margin-right: 6px;
          background-size: cover;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.20024 0.0829432C4.31083 0.193534 4.31083 0.372838 4.20024 0.483428L0.966857 3.71681H7.71681C7.87321 3.71681 8 3.8436 8 4C8 4.1564 7.87321 4.28319 7.71681 4.28319H0.966857L4.20024 7.51657C4.31083 7.62716 4.31083 7.80647 4.20024 7.91706C4.08965 8.02765 3.91035 8.02765 3.79976 7.91706L0.0829432 4.20024C-0.0276477 4.08965 -0.0276477 3.91035 0.0829432 3.79976L3.79976 0.0829432C3.91035 -0.0276477 4.08965 -0.0276477 4.20024 0.0829432Z' fill='black'/%3E%3C/svg%3E%0A");
          @media ${device.$small} {
            height: 10px;
            width: 10px;
          }
        }
      }
      .gallery-title {
        text-align: center;
        font-size:24px;
        font-weight: bold;
        @media ${device.$small} {
          font-size: 32px;
        }
      }
      .gallery-role {
        font-size:14px;
        font-weight: bold;
      }
    }

    .gallery-vid {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
    }

    .gallery-image {
      cursor: pointer;
    }

    .gallery-credits {
      padding: 48px 0;
      font-size: 12px;
    }

    .lightbox {
      display: none;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      z-index: 50;
      &__active {
        display: flex;
      }
      &__image {
        height: 90%;
        width: 90%;
        z-index: 2;
        .spotlight-image {
          display: block;
          margin: 0 auto;
          height: 100%;
          max-width: 100%;
        }
        @media ${device.$small} {
          height: auto;
          width: calc(100vw - 12px);
        }
      }
      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.6);
        .close-mask {
          cursor: pointer;
          z-index: 50;
          height: 48px;
          width: 48px;
          position: absolute;
          top: 48px;
          right: 48px;
          background-position: center;
          background-size: cover;
          background-image: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.5186 1L0.598389 25' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M0.598389 1L24.5186 25' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
          @media ${device.$small} {
            height: 24px;
            width: 24px;
            top: 24px;
            right: 24px;
          }
        }
      }
    }
`

class SingleGalleryPage extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          thisGallery: this.props.data,
          isLightboxActive: false,
          lightboxImage: null,
          template: '1'
      };
  }
  

  openLightbox = (image) => {
    this.setState({ isLightboxActive: true })
    this.setState({lightboxImage: <Img className="spotlight-image" 
                                        fluid={image.asset.fluid} 
                                        alt={image.src} 
                                        style={{ height: "100%", width: "100%" }}
                                        imgStyle={{ objectFit: "contain" }}
                                  /> 
    })
  }

  closeLightbox = () => {
    this.setState({ isLightboxActive: false })
    this.setState({lightboxImage: null })
  }

  render() {
    // console.log('THIS GALLERY', this.state.thisGallery)
    const gallery = this.state.thisGallery.gallery

    const galleryImages = [];


    this.state.thisGallery.gallery.images.forEach(image => {
      galleryImages.push(<div onClick={() => {this.openLightbox(image) } } key={image.asset._id} className="gallery-image"><Img fluid={image.asset.fluid} alt={image.src} /></div>)
    })

    const vimeoIframe = () => {
      if (gallery.vimeo_url) {
        return { __html: gallery.vimeo_url }
      }
    }

    let titlebarRoles = []

    this.state.thisGallery.gallery.role.forEach(role => {
      titlebarRoles.push(role.name)
    })

    let titlebarRolesJoined = titlebarRoles.join(', ')

    return (
      this.state.template = '1' ?
    <>
    <Helmet>
      <title>{this.state.thisGallery.gallery.name} | Imogen WIlson</title>
    </Helmet>
    <Cursor 
      staticText={this.state.thisGallery.siteSettings.nodes[0].cursor_static}
      staticTextColor={this.state.thisGallery.siteSettings.nodes[0].cursor_static_color.hex}
      cursorSparkle={this.state.thisGallery.siteSettings.nodes[0].cursor_sparkle}
    />
    <HeaderAndMenu siteSettings={this.state.thisGallery.siteSettings.nodes[0]} roleData={this.state.thisGallery.roleData.nodes} page={'gallery'}/>
    <MyMarquee color={this.state.thisGallery.siteSettings.nodes[0].marquee_colour?.rgb} content={this.state.thisGallery.siteSettings.nodes[0].marquee_text}/>
      <GalleryPageStyles className="container">
        
        <div className="gallery__title-bar">
          <Link to="/galleries" className="back-button">
            <div className="left-arrow"></div>
            <span>BACK</span>
          </Link>
          <div className="gallery-title">{this.state.thisGallery.gallery.name}</div>
          {/* <div className="gallery-role">{titlebarRolesJoined}</div> */}
        </div>
        {
          vimeoIframe() &&
          <div className="gallery-vid" dangerouslySetInnerHTML={vimeoIframe()}></div>
        }
        
        <Masonry
            breakpointCols={{ default: 2, 375: 1 }}
            className="masonry-grid"
            columnClassName="masonry-grid__column">
        { galleryImages }
        </Masonry>
        <div className={ this.state.isLightboxActive ? 'lightbox lightbox__active' : 'lightbox' }>
          <div className="mask" onClick={() => {this.closeLightbox() }}>
            <div className="close-mask"></div>
          </div>
          <div className="lightbox__image" onClick={() => {this.closeLightbox() }}>
            {this.state.lightboxImage}
          </div>
        </div>
        {
          this.state.thisGallery.gallery._rawCredits &&
            <BlockContent 
                    blocks={this.state.thisGallery.gallery._rawCredits} 
                    renderContainerOnSingleChild={true}
                    className="gallery-credits"
            />
        }
        
    </GalleryPageStyles>
    <Footer siteSettings={this.state.thisGallery.siteSettings.nodes[0]} />
    </>

    :

    <>

      <h1>TEMPLATE 2</h1>

    </>
    )
  }

}

export default SingleGalleryPage



export const query = graphql`
    query($slug: String!) {
        gallery: sanityGallery(slug: {
            current: { eq: $slug }
        }) {
            name
            images {
                asset {
                  _id
                    fluid(maxWidth: 600) {
                    ...GatsbySanityImageFluid_noBase64
                    }
                }
            }
            _rawCredits
            vimeo_url
            role {
              name
            }
            template_style
        }
        siteSettings: allSanitySiteSettings {
            nodes {
              logo {
                asset {
                  fluid(maxWidth: 800) {
                    ...GatsbySanityImageFluid_noBase64
                  }
                  extension
                  url
                }
              }
              menu_colour {
                hex
              }
              menu_text_colour {
                hex
              }
              menu_emoji_one
              menu_emoji_one_url
              menu_emoji_two
              menu_emoji_two_url
              menu_emoji_three
              menu_emoji_three_url
              marquee_colour {
                rgb {
                  a
                  b
                  g
                  r
                }
              }
              marquee_text    
              cursor_static
              cursor_sparkle
              cursor_static_color {
                hex
              }
              mimi_casting_url
              instagram_url
              shop_url
              vimeo_url
              footer_mailto
              hover_gradient_one {
                hex
              }
              hover_gradient_two {
                hex
              }
              hover_gradient_three {
                hex
              }
            }
        }
        roleData: allSanityRole {
          nodes {
            name
            order
          }
        }
    }
`